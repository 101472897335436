.chckt-button {
    display: none;
}

.chckt-sdk {
    padding-bottom: 20px;
    overflow: visible;
}

.adyen-checkout__cvc-tooltip {
    display: inline-flex;
    margin-left: 10px;
    font-size: 18px;
}

.adyen-checkout {
    position: relative;
    z-index: 5;
    min-height: 100px;

    &__wrapper {
        overflow: auto;
    }
}

// OLD RECURRING ONLY STYLES BELOW

// .chckt-pm__header {
//     display: none;
// }

// $adyen_card_fields_height: 165px;

// .adyen-checkout {
//     position: relative;
//     z-index: 5;
//     max-width: 400px;
//     overflow: hidden;
//     &.centred {
//         margin: auto;
//     }
//     &__wrapper {
//         opacity: 0;
//         transition: all 0.5s;
//         //height: $adyen_card_fields_height;
//         min-height: $adyen_card_fields_height;

//         &.has-loaded {
//             opacity: 1;
//             height: auto;
//         }
//     }
// }

// .chckt-sdk {
//     float: none;
//     font-size: inherit;
//     font-family: inherit;
//     margin: 0;
//     min-height: $adyen_card_fields_height;

//     &,
//     * {
//         -moz-osx-font-smoothing: grayscale;
//         -webkit-font-smoothing: antialiased !important;
//         box-sizing: border-box;
//         outline: none !important;
//     }

//     .adyen-checkout__cvc-tooltip {
//         display: inline-flex;
//         margin-left: 10px;
//         font-size: 18px;
//     }

//     .chckt-pm {
//         float: none;
//         background: none;
//         border: none;
//         border-radius: 0;

//         &:first-child,
//         &:last-child {
//             background: none;
//             border: none;
//         }
//     }

//     .chckt-pm-list {
//         float: none;
//     }

//     .chckt-form--max-width {
//         max-width: none;
//     }

//     .chckt-pm:first-child {
//         border: none;
//     }

//     .chckt-pm__header {
//         display: none;
//     }

//     .chckt-pm__details {
//         float: none;
//         padding: 0;
//         background: none;
//     }

//     .chckt-button-container {
//         display: none;
//     }

//     .js-chckt-pm--selected .js-chckt-pm__details {
//         background: none;
//     }

//     .chckt-form-label--exp-date,
//     .chckt-form-label--cvc {
//         width: calc(50% - #{15px / 2});
//     }

//     .chckt-form {
//         //overflow: auto;
//         float: none;
//     }

//     .chckt-form label {
//         // @include inputs__wrap; // we can't change the container bottom padding when the child has error. So I moved the border to child components. James
//         margin-bottom: 15px;
//         padding-bottom: 0;

//         & > span {
//             &:first-child {
//                 padding-left: 15px;
//                 padding-right: 15px;
//                 border-style: solid;
//                 border-color: $colors_grey_xlight !important;
//                 min-width: 100%;
//                 max-width: 100%;
//                 padding-top: 8px;
//                 border-top-left-radius: 4px;
//                 border-top-right-radius: 4px;
//                 border-bottom-left-radius: 0px;
//                 border-bottom-right-radius: 0px;
//                 border-width: 1px 1px 0px;
//             }
//             &:nth-child(2) {
//                 padding-left: 15px;
//                 padding-right: 15px;
//                 border-style: solid;
//                 border-color: $colors_grey_xlight !important;
//                 min-width: 100%;
//                 max-width: 100%;
//                 padding-bottom: 8px;
//                 height: 26px;
//                 border-top-left-radius: 0px;
//                 border-top-right-radius: 0px;
//                 border-bottom-left-radius: 4px;
//                 border-bottom-right-radius: 4px;
//                 border-width: 0px 1px 1px;
//             }
//             &:last-child{
//                 padding-left: 16px;
//                 padding-top: 5px;
//                 line-height: 16/13em;
//                 font-weight: bold;
//                 color: $colors_primary_red;
//             }
//         }
//     }

//     .chckt-input-field--error + .chckt-form-label__error-text {
//         padding-top: 1em;
//     }

//     .chckt-form__title,
//     .chckt-form-label__text {
//         @include inputs__label;
//         text-align: left;
//         color: $colors_grey_dark;
//     }

//     .chckt-input-field {
//         @include inputs__input;
//         padding: 0;
//         height: 1.125em;
//     }

//     .chckt-pm__pending-message {
//         display: none;
//     }

//     input[type="checkbox"] {
//         appearance: checkbox;
//     }
// }
