$counter_dot_height: 1.7em;

.counter-dot {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 $counter_dot_height;
    height: $counter_dot_height;
    width: $counter_dot_height;
    border-radius: 100%;
    color: $colors_primary_white;
    background: $colors_primary_red;
    font-weight: bold;
    font-style: normal;
    font-size: 0.77em;
    line-height: 1.2em;

    &--floating {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        font-size: 12px;
    }

    &--green {
        background: $colors_primary_green;
    }

    &__text {
        width: 100%;
        text-align: center;

        &--small {
            font-size: 11px;
            margin-top: 1px;
        }
    }

    &__plus {
        margin-left: -2px;
    }
}
