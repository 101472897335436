.payment-methods {
    &__empty {
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 20px;
    }
    &__smallprint {
        padding: 0 20px;
        margin: 0;

        @media (max-width: $breakpoint_md) {
          padding: 0px;
        }
    }
}
